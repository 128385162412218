<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.nome"
        class="col-12"
        :label="$t('modulos.roteiro_motivo_cancelamento.formulario.nome')"
        obrigatorio
        :max="500"
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import RoteiroMotivoCancelamentoService from '@common/services/cadastros/RoteiroMotivoCancelamentoService.js';
import { PrioridadeModel } from '@common/models/cadastros/PrioridadeModel.js';
import helpers from '@/common/utils/helpers';
export default {
  props: ['id'],
  data() {
    return {
      valido: false,
      form: new PrioridadeModel({}),
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.roteiro_motivo_cancelamento.titulos.editar');
      return this.$t('modulos.roteiro_motivo_cancelamento.titulos.novo');
    },
  },
  mounted() {
    if (this.id) {
      this.buscar(this.id)
      helpers.redirecionarSemPermissao(this, 'RoteiroMotivoCancelamento', 'Editar')
    }
    helpers.redirecionarSemPermissao(this, 'RoteiroMotivoCancelamento', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      RoteiroMotivoCancelamentoService.buscar(this.id)
        .then((res) => {
          this.form = new PrioridadeModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.roteiro_motivo_cancelamento.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if(!this.valido) return
      this.$store.dispatch('Layout/iniciarCarregamento');
      RoteiroMotivoCancelamentoService.salvar(this.form)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.roteiro_motivo_cancelamento.cadastro_sucesso`));
          this.$router.push({ name: 'motivo-cancelamento-roteiro' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'motivo-cancelamento-roteiro' });
      });
    },
  },
};
</script>
